<template>
  <div>
    <b-card no-body>
      <div
        ref="uploadCardContent"
      >
        <div
          ref="uploadCard"
          no-body
          class="image-explorer-contain upload-card"
          @dragenter="dragEnter"
          @dragover="preventDefault"
          @dragleave="dragLeave"
          @drop="dropFile"
        >
          <div
            ref="dropArea"
            class="dropArea hidden"
          >
            <div class="upload-label">
              <span class="label-title">
                把圖片拖放到這裡
              </span>
              <b-img
                :src="require('@/assets/images/icons/image-processing.png')"
                fluid
              />
            </div>
          </div>

          <div class="border m-1">
            <label class="upload-label-text">
              <span class="mb-1">
                把圖片拖放到這裡或者 <strong class="cursor-pointer text-primary">點擊這裡</strong> 選擇檔案
              </span>
              <input
                ref="fileInput"
                type="file"
                accept=".jpg, .png, .gif .tiff, .bmp .ico .psd .webp"
                hidden
                @input="resolveFileInput($event)"
              >
              <!-- multiple -->
              <div>
                <b-img
                  :src="require('@/assets/images/icons/image-processing.png')"
                  fluid
                  class="events-content"
                />
              </div>
            </label>
          </div>
        </div>
      </div>

    </b-card>

    <b-card v-if="previewFile.length > 0">
      <b-row class="match-height image-view-container">
        <b-col
          v-for="(image, index) in previewFile"
          :key="index"
          cols="6"
          xl="3"
          lg="3"
          md="4"
          class="mb-1 d-flex align-items-center"
        >
          <div
            class="image-view"
            :class="!isDark ? 'image-light' : 'image-view-dark'"
          >
            <div class="image-view-header">
              <div class="image-view-title cursor-pointer">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="image.name"
                  class="image-view-title-text"
                >
                  {{ image.name }}
                </span>
              </div>
            </div>

            <div class="image-view-body">
              <span
                class="image-view-remove cursor-pointer"
                @click="handleRemoveSite(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="remove-icon "
                />
              </span>

              <div
                class="w-100 h-100 d-flex align-items-center justify-content-center"
                @click="showImgs(index)"
              >
                <b-img-lazy
                  :src="image.src"
                  fluid
                  :alt="image.name"
                />
              </div>
            </div>

            <div class="text-right mt-50">
              <b-button
                variant="outline-primary"
                size="sm"
                class="mr-50"
                @click="showBase64(image)"
              >
                原始碼
              </b-button>

              <b-button
                variant="outline-primary"
                size="sm"
                @click="copyBase64(image)"
              >
                複製
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="imageSiteModal"
      no-close-on-backdrop
      no-close-on-esc
      centered
      body-class="p-0"
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          Base64 原始碼
        </h4>
      </template>

      <div
        v-if="siteData"
        class="site-modal p-1 w-100"
      >
        <BFormTextarea
          v-model="siteData.base64"
          rows="16"
          autofocus
          class="site-modal-area"
        />
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="copyBase64(siteData)"
          >
            複製
          </b-button>

          <b-button
            variant="flat-primary"
            @click="handleHideSiteModal"
          >
            <!-- @click="handleConfirmSiteModal" -->
            確認
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BCard, BImg, BRow, BCol, BButton, BImgLazy, VBTooltip, BFormTextarea, // BBadge,
} from 'bootstrap-vue'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    // BBadge,
    BButton,
    BImgLazy,
    BFormTextarea,

    // ImageHistory,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      siteFile: [],
      previewFile: [],
      siteData: null,
    }
  },
  methods: {
    // (轉化)檔案大小
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },
    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation() // 終止事件傳導
      e.preventDefault() // 終止預設行為
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files, types } = e.dataTransfer

      const [file] = files
      const maxSize = 3 * 1024 * 1024 // 3MB in bytes
      if (file.size > maxSize) {
        this.useAlertToast(false, '圖片太大，請選擇大小不超過3MB的圖片')
        this.$refs.fileInput.value = null
        this.$refs.dropArea.classList.add('hidden')
        return
      }

      this.siteFile = Object.values(files)

      if (!types.includes('Files')) return
      if (this.siteFile.length === 0) return
      // this.$refs.dropArea.classList.add('hidden')

      const image = new Image()
      const resolveFile = {}
      const objectURL = window.URL.createObjectURL(file)

      const type = this.imageTypeList[file.type]
      if (!type) return

      resolveFile.name = file.name
      resolveFile.image = file
      resolveFile.type = type
      image.src = objectURL
      resolveFile.src = objectURL
      resolveFile.size = this.convertBytes(file.size)
      // resolveFile.base64 = await this.readFileAsBase64(file)
      image.onload = () => {
        this.previewFile.push(resolveFile)
        this.$refs.fileInput.value = null
        this.$refs.dropArea.classList.add('hidden')
      }
    },

    // async

    resolveFileInput(e) {
      this.preventDefault(e)
      if (e.target.files.length === 0) return
      const { files } = e.target
      const [file] = files

      const maxSize = 3 * 1024 * 1024 // 3MB in bytes
      if (file.size > maxSize) {
        this.useAlertToast(false, '圖片太大，請選擇大小不超過3MB的圖片')
        this.$refs.fileInput.value = null
        return
      }

      const image = new Image()
      const resolveFile = {}
      const objectURL = window.URL.createObjectURL(file)

      const type = this.imageTypeList[file.type]
      if (!type) return

      resolveFile.name = file.name
      resolveFile.image = file
      resolveFile.type = type
      image.src = objectURL
      resolveFile.src = objectURL
      resolveFile.size = this.convertBytes(file.size)
      // resolveFile.base64 = await this.readFileAsBase64(file)

      image.onload = () => {
        this.previewFile.push(resolveFile)
        this.$refs.fileInput.value = null
      }
    },

    // ------------------------------------------------------------------------------------
    // (轉成)Base64
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },

    // (顯示)Base64
    async showBase64(item) {
      const resolveFile = {
        ...item,
      }
      resolveFile.base64 = await this.readFileAsBase64(item.image)
      this.siteData = resolveFile
      this.$bvModal.show('imageSiteModal')
    },

    // (複製)Base64
    async copyBase64(item) {
      const resolveFile = {
        ...item,
      }
      resolveFile.base64 = await this.readFileAsBase64(item.image)
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.writeText(resolveFile.base64)
        .then(() => {
          this.useAlertToast(true, 'Base64已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (觸發)圖片顯示
    showImgs(index) {
      const newArray = this.previewFile.slice(index).concat(this.previewFile.slice(0, index)).map(item => item.src)
      this.$viewerApi({
        images: newArray,
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)移除圖片
    handleRemoveSite(index) {
      this.previewFile.splice(index, 1)
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      this.$bvModal.hide('imageSiteModal')
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const imageTypeOptions = [
      { label: 'JPEG', value: 'jpg', type: 'image/jpeg' },
      { label: 'GIF', value: 'gif', type: 'image/gif' },
      { label: 'PNG', value: 'png', type: 'image/png' },
      { label: 'TIFF', value: 'tiff', type: 'image/tiff' },
      { label: 'BMP', value: 'bmp', type: 'image/bmp' },
      { label: 'ICO', value: 'ico', type: 'image/x-icon' },
      { label: 'PSD', value: 'psd', type: 'image/vnd.adobe.photoshop' },
      { label: 'WebP', value: 'webp', type: 'image/webp' },
    ]

    const imageTypeList = {}
    imageTypeOptions.forEach(option => {
      imageTypeList[option.type] = option.value
    })

    const {
      useAlertToast,
    } = useAlert()

    return {
      isDark,
      imageTypeList,
      useAlertToast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.upload-card {
  .dropArea {
    display: block;
    min-height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 0 20px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #282828;
    opacity: .8;
    // backdrop-filter: blur(10px);
    text-align: center;
    color: #fff;
    // text-shadow: 0em 0.01em #333, 0em 0.02em #333, 0em 0.02em 0.03em #333;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 15vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 1.5rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
    img {
      max-width: 50px;
    }
  }

  .upload-label-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1rem;
    // font-weight: 900;
    min-height: 20vh;
    padding: 1rem;
    // font-size: 1.5rem;
    cursor: pointer;
    img {
      max-width: 50px;
    }
  }
}

.events-content {
  pointer-events: none;
}

.site-modal {
  width: 100%;
  max-height: 60vh;
  .site-modal-area {
    // max-width: 100%;
    word-break: break-all;
    // overflow-x: hidden;
  }
}

.image-view-container {
  margin-top: 10px;
  .image-view {
    position: relative;
    padding: 5px;
    width: 100%;
    .image-view-header {
      display: flex;
      justify-content: center;

      .image-view-title {
        padding: 10px 0;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        .image-view-title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
        }
      }
    }

    .image-view-body {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 180px;
      padding: 10px;
      border-radius: 10px;
      img {
        max-height: 160px;
        cursor: pointer;
      }

      .image-view-remove {
        position: absolute;
        top: -10px;
        right: -6px;
        color: white;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: 0;
        &:hover {
          background-color: #c40c0cb3;
        }
      }
    }

    &:hover {
      border-radius: 10px;
      border: #4683bb 1px solid;
      margin: -1px;

      .image-view-body {
        .image-view-remove {
          opacity: 1;
        }
      }
    }
  }

  .image-light {
    .image-view-body {
      background-color:#00000010;
      .image-view-remove {
        background-color: #3c3c3cb3;
      }
    }
  }

  .image-view-dark {
    .image-view-body {
      background-color:#ffffff10;
      .image-view-remove {
        background-color: #898989;
      }
    }
  }
}

</style>
